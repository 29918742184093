@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.tk-card {
  @apply bg-white rounded h-[350px] md:h-[410px] overflow-hidden shadow-md relative;
}

.tk-badge {
  @apply bg-custom overflow-hidden text-white text-xs uppercase w-fit font-bold  p-2 absolute right-0 bottom-[45%] md:bottom-[42%] ml-2 mt-2;
}
